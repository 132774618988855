
import { defineComponent, onMounted } from "vue";
import KTModalCard from "@/components/cards/Card.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { getIllustrationsPath } from "@/core/helpers/assets";

export default defineComponent({
  name: "Modal-Page",
  components: {
    KTModalCard,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("PopUp App", ["Modals", "Wizards"]);
    });

    return {
      getIllustrationsPath,
    };
  },
});
